#products {
    padding: 1rem 6rem;
    position: relative;
    z-index: 120;
    min-height: 150vh; /* Adjusted min-height */
    top: 600vh; /* Adjusted top position */
    background-color: black;
    box-sizing: border-box;
}

.products-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Added for vertical alignment */
    padding: 2rem 0rem;
}

.products-header-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 3rem 0rem;
}

.products-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    font-weight: 800;
    background: linear-gradient(to right, #941ECF 30%, #CF1512 95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.products-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 4rem;
    row-gap: 3rem;
    justify-content: space-between;
    padding: 2rem 0rem;
}

@media (max-width: 1200px) {
    #products {
        padding: 2rem;
    }

    .products-grid {
        grid-template-columns: auto auto;
        column-gap: 2rem;
        row-gap: 2rem;
    }
}

@media (max-width: 750px) {
    #products {
        padding: 1rem;
    }

    .products-grid {
        grid-template-columns: auto;
        row-gap: 1rem;
    }
}
