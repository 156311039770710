#contact{
    padding: 1rem 6rem;
    flex:1;
    position: relative;;
    z-index: 140;
    min-height: 100vh;
    top: 600vh;
    background-color: black;
    background: black;
    box-sizing: border-box;


}

.contact-container{
    display: flex;
    flex-direction: column;
}

.contact-upper-container{
    display: flex;
    width: 100%;
    align-items: center;  /* This will vertically center all children including .products-title */
    justify-content: center;  /* This will horizontally center all children */
    padding: 3rem 0rem 1rem 0rem;
}

.contact-lower-container{
    display: flex;
    width: 100%;
    align-items: center;  /* This will vertically center all children including .products-title */
    justify-content: center;  /* This will horizontally center all children */
    padding: 1rem 0rem 1rem 0rem;

}



.contact-header{
    display: flex;  /* Makes this element a flex container */
    align-items: center;  /* Vertically centers content in the flex container */
    justify-content: center;  /* Horizontally centers content in the flex container */
    font-size: 45px;
    font-weight: 800;
    background: linear-gradient(to right, #941ECF 30%, #CF1512 95%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}



.contact-left-container{
    flex: 1;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.seperator{
    flex: 0.5;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.seperator::after{
    content: '';
    position: absolute;
    left: 50%;
    top: 150%;
    height: 100px;
    border-left: 2px solid white;

}

.seperator::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 150%;
    height: 100px;
    border-left: 2px solid white;

}


.contact-form{
    
    background: #ffffff;
    color: black;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 40vh;
    border-radius: 10px;
}

.input-label{
    font-weight: 500;
}

.input-tag{
    background-color:#eaeafa;
    border-radius: 10px;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.75rem 1rem;
    margin: 1rem 0rem;
    box-shadow: 2px 2px 1px rgba(0,0,0,0.2);

    resize: none; /* This disables resizing the textarea */
    overflow-y: auto; /* This adds a vertical scrollbar when needed */
 
    width: 100%; /* Adjust the width as needed */
    box-sizing: border-box;
}

.submit-button{
    border: none;
    margin-top: 0.5rem;
    box-shadow: 2px 2px 1px rgba(0,0,0,0.2);
    background-color: purple;
    padding: 0.75rem;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;

}

.contact-right-container{
padding: 2rem;
flex: 1;
align-items: center;
justify-content: center;

}

.contact-links-email-header, .contact-links-social-header, .contact-links-social-header1{
    font-weight: 600;
    font-size: 22px;
    padding: 2rem 0rem;
}

.contact-links-social-header1{
    text-align: left;
    margin-left: -80px;
}

.contact-links{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
}
.contact-link{

    display: flex;
    padding: 1rem 0rem;
    align-items: center;
    justify-content: left;


}

.business-details{
    padding-left: 20%;
}



a, .social-link-label{
    text-decoration: none;

    cursor: pointer;
    color: rgba(255, 255, 255, 0.9);;

    margin-left: 0.75rem;
    font-size: 18px;
    margin-right: 1.5rem;
    font-weight: 600;
}
.social-link-label img{
    margin: 0;
    margin-left: 0rem;

    max-height: 26px;
    justify-content: left;
}

a, .social-link-label:hover{
    color: rgba(255, 255, 255, 1);

}

.error-message{
    color: red;
    font-size: 14px;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
}



@media (max-width: 1200px) {

    #contact{
        padding: 0rem;
    }

 }
 
 @media (max-width: 750px) {

    #contact{
        padding: 1rem;
        width: 100%;
    }

    .contact{
        justify-content: center;
        align-items: center;
    }

    .contact-upper-container{
        padding: 1rem 0rem;
    }
 
    .contact-left-container, .contact-right-container{
 
        padding: 0rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

.contact-form{
    padding: 1rem ;
}
    
    
     
    
        .contact-lower-container{
            display: flex;
            flex-direction: column;
        
        }
    
        

        

        .contact-link{
            align-items: center;
            justify-content: center;
            padding:  0rem;

        }

        .contact-links{
            text-align: center;
        }

        .seperator{
            margin-top: 3rem ;
        }

        .seperator::after{
            content: '';
            position: absolute;
            transform: translateX(20%);
            top: 50%;
            width: 100px;
            height: 2px;
            background: white;
        
        }
        
        .seperator::before{
            content: '';
            position: absolute;
            transform: translateX(-120%);
            top: 50%;
            width: 100px;
            height: 2px;
            background: white; 
        
        }

        .contact-links-social-header1{
            margin-left: 0;
        }

        .business-details{
            padding-left: 0;
            padding-bottom: 50px;
        }


     
     
 
 }
 
 @media screen and (max-width : 385px) {
   
 
 }