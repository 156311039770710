#customersupport {
    padding: 1rem 6rem;
    z-index: 100;
    position: relative;
    max-height: 100vh;
    max-width: 100vw;
    top: 500vh;
    background-color: black;
    background: black;
    display: flex;
    box-sizing: border-box;


}

.customersupport-left-container,
.customersupport-right-container {
    flex: 1 1 50%;
}


.customersupport-container {
    padding: 4rem 0rem;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
}

.customersupport-right-container {
    padding: 1rem 0rem 1rem 0rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    margin-right: 75px;

}

.customersupport-right-upper-container {
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 3rem;
    line-height: 3.5rem;
    background: linear-gradient(to right, #941ECF 20%, #CF1512 75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.customersupport-right-lower-container {
    text-align: justify;
    font-size: clamp(14px, 1vw, 18px);
    max-height: 20vh;
    font-weight: 600;
    line-height: 1.75rem;
}

.customersupport-left-container {
    max-height: 70vh;
    margin-right: 75px;


}



#customersupport .customersupport-left-container .image {
    z-index: 100;

    max-height: 70vh;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 80px rgba(0, 0, 0, 0.8);
}


@media (max-width: 1200px) {



    .side-buttons {
        display: none;
    }

    #customersupport {
        padding: 2rem;
    }

    .customersupport-container {
        gap: 50px;
    }

    .customersupport-left-container,
    .customersupport-right-container {
        margin: 10px;
    }

    .customersupport-right-upper-container {
        font-size: 30px;
        line-height: 3.5rem;
        margin-bottom: 2rem;

    }

    .customersupport-right-lower-container {
        font-size: 16px;
        line-height: 1.5rem;

    }

}

@media (max-width: 750px) {



    .side-buttons {
        display: none;
    }

    #customersupport {
        padding: 0rem 1rem;
        box-sizing: border-box;

    }

    .customersupport-left-container {
        padding: 0.5rem 0rem 1rem 0rem;
    }

    .customersupport-container {
        margin: 0;
        /* Removes default margin */
        padding: 0.5rem;
        gap: 0;
    }

    .customersupport-left-container,
    .customersupport-right-container {
        margin: 0;
    }

    .customersupport-container {
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .customersupport-right-upper-container {
        font-size: 30px;
        line-height: 2rem;
        margin-bottom: 1rem;

    }

    .customersupport-right-lower-container {
        font-size: 14px;
        line-height: 1.4rem;

    }

    .customersupport-left-container {
        max-height: 40vh;
    }

    .customersupport-left-container img {
        max-width: 100%;
        max-height: 45vh;
    }

}

@media screen and (max-width : 385px) {
    .customersupport-right-upper-container {
        font-size: 20px;

    }

    .customersupport-right-lower-container {
        font-size: 12px;
        line-height: 1.2rem;

    }

}