body {
  background-color: black;
}
 
:root{
  --font-family: Arial, Helvetica, sans-serif;
  --color-bg: #000000;
  --color-footer : #031B34;
  --gradient-text: linear-gradient(to right,#941ECF  20%, #CF1512 75%);
  --gradient-bg:linear-gradient(135deg, rgba(0,40,83,1) 35%, rgba(94,1,20,1) 94%);
  --gradient-bg-flipped: linear-gradient(55deg, rgba(0,40,83,1) 35%, rgba(94,1,20,1) 94%);
  --gradient-bg-flipped-2: linear-gradient(200deg, rgba(0,40,83,1) 35%, rgba(94,1,20,1) 94%);

}


