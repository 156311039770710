#footer {
    padding: 0.75rem;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(0, 0, 30);
    z-index: 200;
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* .footer-container,
.footer-content-container {
    color: white;
    flex: 1;
    font-weight: 18px;
    font-weight: 700;
    text-align: center;

} */


.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* Space between link section and copyright */
    text-align: center;
    flex: 1;
    color: white;
}

.footer-container div:first-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    /* Space between the links */
}

.footer-container a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    /* Consistent font size */
    font-weight: 700;
}

.footer-container a:hover {
    color: #007BFF;
    /* Change color on hover */
}

.footer-content-container {
    margin-top: 5px;
    /* Space between links and copyright text */
    font-weight: 700;
    font-size: 16px;
    /* Font size for the copyright section */
}

@media (max-width: 750px) {

    .footer-container,
    .footer-content-container {
        font-size: 12px;
        padding: 0.25rem;

    }


}