#salesassistant{
    padding: 1rem 6rem;
    z-index: 80;
    position: relative;
    max-height: 100vh;
    max-width: 100vw;
    top: 400vh;
background-color: black;
    background: black;
    display: flex;


}




.salesassistant-container{
    padding: 4rem 0rem;
    display: flex;
    justify-content: space-between;
    gap: 100px;
    min-height: 100vh;
}

.salesassistant-left-container, .salesassistant-right-container{
    flex: 1 1 50%;
}

.salesassistant-left-container{
    padding: 1rem 0rem 1rem 0rem;
    align-items:center;
    display: flex;
    flex-direction: column;
}

.salesassistant-left-upper-container{
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 3rem;
    line-height: 3.5rem;
    background: linear-gradient(to right,#941ECF  20%, #CF1512 75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.salesassistant-left-lower-container{
    text-align: justify;
    /* font-size: 16px; */
    /* font-size: 1.2vw; Adjust the size based on your design needs */
    font-size: clamp(14px, 1vw, 18px);  /* Adjusts between 14px and 18px based on 1% of viewport width */
    font-weight: 600;
    line-height: 1.75rem;
    
}

.salesassistant-right-container {
    max-height: 100vh;
    align-items: center;
    justify-content: center;


}

.salesassistant-right-container, img{
    width: 100%;  
    max-height: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 80px rgba(0,0,0,0.8);
    z-index: 80;
}




 @media (max-width: 1200px) {

   
    .side-buttons{
        display: none;
    }

    #salesassistant{
        padding: 2rem;
    }

    .salesassistant-container{
        gap: 50px;
    }

    .salesassistant-left-container, .salesassistant-right-container{
        margin: 10px;
    }

    .salesassistant-left-upper-container{
        font-size: 30px;
    line-height: 3.5rem;
    margin-bottom: 2rem;

    }

    .salesassistant-left-lower-container{
        font-size: 16px;
        line-height: 1.5rem;

    }

}

@media (max-width: 750px) {



    .side-buttons{
        display: none;
    }

    #salesassistant{
        padding: 0rem 1rem;
        box-sizing: border-box;
        
    }

    .salesassistant-right-container{
        padding: 0.5rem 0rem 1rem 0rem;
    }

    .salesassistant-container {
        margin: 0; /* Removes default margin */
        padding: 0.5rem;
        gap: 0;
    }

    .salesassistant-right-container, .salesassistant-left-container{
        margin: 0;
    }

    .salesassistant-container{
        flex-direction: column;
        text-align: center;
        justify-content:start;
        min-height: 100vh;
    }

    .salesassistant-left-upper-container{
        font-size: 30px;
        line-height: 2rem;
        margin-bottom: 1rem;

    }

    .salesassistant-left-lower-container{
        font-size: 14px;
        line-height: 1.4rem;

    }

    .salesassistant-right-container img{
            max-width: 100%;
            max-height: 45vh;
        }

}

@media screen and (max-width : 385px) {
    .salesassistant-left-upper-container{
        font-size: 20px;

    }

    .salesassistant-left-lower-container{
        font-size: 12px;
        line-height: 1.2rem;

    }

 


}
