body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background: var(--color-bg);
  color: white;

}

.back-to-top-bttn {
  border: none;
  cursor: pointer;
  font-size: 15px;
  background-color: purple;
  color: white;
  font-weight: 600;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  /* perfectly circular */
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top-progress {
  position: fixed;
  opacity: 0.75;

  right: 20px;
  bottom: 20px;
  width: 56px;
  /* slightly larger than the button */
  height: 56px;
  /* slightly larger than the button */
  display: flex;
  align-items: center;

  justify-content: center;
  border-radius: 50%;
  /* ensures circular shape */
  background: conic-gradient(white 0deg, white 0deg, transparent 0deg);
  /* initially set to no progress */
  transition: background 0.25s ease-out;
  z-index: 999;
  /* ensure it's properly layered */
}




.side-buttons {
  position: absolute;
  right: 30px;
  padding: 14rem 0rem;
  box-sizing: border-box;
  height: 90vh;
  align-items: center;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
}

.side-button {
  cursor: pointer;
  padding: 0.4rem 0.6rem;
  color: white;
  font-size: 14px;
  border: 2px solid white;
  border-radius: 16px;
  position: relative;
  background-color: black;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.side-button:hover {
  background-color: rgb(70, 70, 70);
}

.side-button:not(:last-child)::before {
  content: '';
  position: absolute;
  left: 50%;
  /* Center the line relative to the button */
  top: 100%;
  /* Start the line right below the button */
  height: 175%;
  /* Height to reach the next button; adjust as needed */
  transform: translateX(-50%);
  /* Center the line horizontally */
  border-left: 2px solid white;
}

.clear {
  clear: both;
}

@media (max-width: 750px) {
  .back-to-top-bttn {
    height: 40px;
    width: 40px;
  }

  .back-to-top-progress {
    height: 44px;
    width: 44px;
  }
}



/* Ensure the content has enough space below the fixed navbar */
.policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 120px 20px 20px;
  /* Add top padding to create space below the navbar */
  background-color: var(--color-bg-light);
  color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  line-height: 1.6;
}

/* Navbar styling (Assumed the navbar is fixed) */


.policy-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--color-primary);
  /* Primary color for headings */
}

.policy-container p,
.policy-container li {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--color-text);
}

.policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.policy-container .last-updated {
  font-style: italic;
  color: var(--color-text-light);
  margin-bottom: 2rem;
}

/* Responsive adjustments */
@media (max-width: 750px) {
  .policy-container {
    padding: 120px 15px 20px;
    /* Adjust padding for smaller screens */
  }

  .policy-container h1 {
    font-size: 2rem;
  }

  .policy-container p,
  .policy-container li {
    font-size: 1rem;
  }
}