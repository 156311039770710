.navbar {
    padding: 2rem 6rem;
    display: flex;
    align-items: center;
    flex: 1;
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: black;
    /* Ensures padding is included within the width */
    z-index: 10;


}

.navbar-container {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.navbar-logo {
    margin-right: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-name {
    color: white;
    font-size: 28px;
    margin-left: 10px;
    font-weight: 800;
    letter-spacing: 4px;


}


.navbar-logo img {
    height: 70px;
}

.navbar-links-container {
    display: flex;
    flex-direction: row;
}

.navbar-links-container a {
    text-decoration: none;
    color: rgb(220, 220, 220);
    font-size: 18px;
    margin: 0 2rem;
    font-weight: 700;

    cursor: pointer;
}

.navbar-links-container a:hover {
    color: #fff;
}


.navbar-social-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0rem 1.2rem;
    cursor: pointer;
}

.navbar-menu-mobile-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    padding: 2rem;
    top: 100px;
    right: 12px;
    background-color: #111;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}



.navbar-menu-mobile svg {
    cursor: pointer;
    z-index: 5;

}

.navbar-menu-mobile-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;

}

.navbar-menu-mobile-links a {
    color: #fff;
    font-size: 18px;
    margin: 1 0rem;
    font-weight: 700;

}

.navbar-menu-mobile {
    display: none;
}

@media screen and (max-width : 1150px) {

    .navbar {
        padding: 2rem;
    }

    .navbar-container {
        flex: 1;

    }

    .navbar-name {
        font-size: 22px;
        margin-left: 5px;


    }

    .navbar-links-container {
        display: none;
    }

    .navbar-menu-mobile {
        z-index: 10000;
        display: flex;
        flex-direction: column;
        /* justify-content: flex-end; */
    }

    .navbar-menu-mobile {
        display: block;

    }
}

@media screen and (max-width : 750px) {

    .navbar-logo img {
        max-height: 50px;
        /* Smaller logo on very small screens */
    }


    .navbar {
        flex: 1;
        padding: 1.5rem 1.5rem 0rem 1.5rem;
        box-sizing: border-box;
    }

    .navbar-menu-mobile-container {
        z-index: 10000;
        right: 10px;
    }



    .navbar-social-container {
        z-index: 10000;
        display: none;
    }
}

@media screen and (max-width : 376px) {
    .navbar-name {
        font-size: 18px;
    }
}