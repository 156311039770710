.product-container{
    display: flex;
    flex-direction: column;
    padding: 0rem;
    background-color: rgb(30, 20, 30) ;
    box-shadow: 0 0px 25px 0 rgba(238, 227, 227, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    align-items: center; 
    text-align: center;
    max-height: 85vh;
    min-height: 75vh;
}

.product-image-container {
    min-height: 30vh;
    max-height: 35vh;  /* Sets maximum height for the container */
    overflow: hidden;  /* Prevents any overflow beyond the container's set dimensions */
    align-items: center; /* Centers the image vertically within the container */
    justify-content: center; /* Centers the image horizontally within the container */
    position: relative;
    
}

.product-image-container img {
    width: 100%;  /* Automatically adjusts the width to maintain aspect ratio */
    max-height: 100%;  /* Ensures the image height does not exceed the container's height */
    object-fit: contain;  /* Makes sure the entire image is visible within the container */
}

.coming-soon-text{
    position: absolute;
    font-size: 40px;
    font-weight: 600;
    z-index: 110;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.product-details-container{
    padding: 1rem 2rem;
}

.product-title-container{
    flex: 1;
    color: rgb(250, 250, 250);
    font-size: 22px;
    font-weight: 800;
    padding: 0.5rem 0rem;
}

.product-description-container{
    color: rgb(220, 220, 220);
    font-size: 14px;
    line-height: 1.25;
    font-weight: 500;
    text-align: justify;
    padding-top: 1rem;
}


@media (max-width: 1200px) {

   .product-container{
    max-width: 80vh;
    min-height: 65vh;

   }


}

@media (max-width: 750px) {



}

@media screen and (max-width : 385px) {
  

}