#header{
    padding: 1rem 6rem;
    display: flex;
    z-index: 0;
    max-width: 100vw;
    position: fixed;
    top: 100px;
}

.header-container{
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 175px;
    max-height: 80vh;
} 

.header-container-left, .header-container-right {
    flex: 1 1 50%; 
}

.header-container-left{
    padding: 1rem 0rem 1rem 0rem;
    align-items:center;
    display: flex;
    flex-direction: column;
}



.header-container-left-upper{
    font-size: 55px;
    font-weight: 800;
    margin-bottom: 3rem;
    line-height: 4.5rem;
    background: linear-gradient(to right,#941ECF  20%, #CF1512 75%);
    /* background: purple; */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.header-container-left-lower{
    text-align: justify;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.75rem;
}

.header-container-right {
    max-height: 80vh; 
}

.header-container-right img {
    width: 100%;  
    max-height: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 80px rgba(0,0,0,0.8);
}

@media (max-width: 1200px) {

    #header{
        padding: 2rem;
    }

    .header-container{
        gap: 50px;
    }

    .header-container-left-upper{
        font-size: 40px;
    line-height: 3.5rem;
    margin-bottom: 2rem;

    }

    .header-container-left-lower{
        font-size: 16px;
        line-height: 1.5rem;

    }

}

@media (max-width: 750px) {

    #header{
        padding: 0rem 1rem;
        box-sizing: border-box;
        
    }

    .header-container-left{
        padding: 0.5rem 0rem 1rem 0rem;
    }

    .header-container {
        margin: 0; /* Removes default margin */
        gap: 0;
    }

    .header-container{
        flex-direction: column;
        text-align: center;
        justify-content:start;
        align-items: center;
    }

    .header-container-left-upper{
        font-size: 30px;
        line-height: 2rem;
        margin-bottom: 1rem;

    }

    .header-container-left-lower{
        font-size: 14px;
        line-height: 1.4rem;

    }

    .header-container-right .image{
            max-width: 100%;
            height: 100%;
        }

}

@media screen and (max-width : 385px) {
    .header-container-left-upper{
        font-size: 26px;

    }

    .header-container-left-lower{
        font-size: 12px;
        line-height: 1.2rem;

    }

 


}



