/* PricingTable.css */
.pricing-header {
    background-color: black;
    color: white;
    text-align: center;
    font-size: 3rem;
    padding-bottom: 0.5rem;
    font-weight: 600;
    width: 100%;
    padding-top: 10rem;
    /* Add padding to account for navbar height */
}

.pricing-container {
    display: flex;
    justify-content: space-evenly;
    padding-top: 4rem;
    /* Slight padding adjustment */
    background-color: black;
    max-width: 100%;
    flex-wrap: wrap;
    padding-bottom: 3rem;
    /* For bottom spacing */
}

.plan-card {
    background-color: #1e141e;
    color: white;
    border-radius: 15px;
    box-shadow: 0 0px 25px 0 rgba(238, 227, 227, 0.2), 0 6px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    max-width: 20%;
    min-width: 20%;
    margin: 1rem;
    flex: 1;
}

.plan-card.popular {
    background-color: #2d1e2d;
}

.plan-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: white;
}

.plan-title.popular-title {
    color: purple;
}

.plan-price {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 1rem;
}

.plan-description {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
}

.plan-features {
    text-align: left;
    margin-bottom: 2rem;
    list-style-type: none;
    padding: 0;
}

.plan-features li {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.plan-button {
    border: none;
    box-shadow: 2px 2px 1px #0003;
    background-color: purple;
    padding: 0.75rem;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
    text-transform: none;
}

@media (max-width: 768px) {
    .pricing-container {
        flex-direction: column;
    }

    .plan-card {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 2rem;
    }

    .plan-title,
    .plan-button {
        font-size: 1.2rem;
    }

    .plan-features li {
        font-size: 1.2rem;
    }

    .plan-button {
        font-size: 1rem;
        padding: 1rem;
    }
}