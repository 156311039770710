#interview{
    padding: 1rem 6rem;
    z-index: 20;
    position: relative;
    max-height: 100vh;
    max-width: 100vw;
    top: 100vh;
background-color: black;
    background: black;
    display: flex;
    box-sizing: border-box;


}

.interview-left-container, .interview-right-container{
    flex: 1 1 50%;
}


.interview-container{
    padding: 4rem 0rem;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
}

.interview-right-container{
    padding: 1rem 0rem 1rem 0rem;
    align-items:center;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    margin-right: 75px;

}

.interview-right-upper-container{
    font-size: 42px;
    font-weight: 800;
    margin-bottom: 3rem;
    line-height: 3.5rem;
    background: linear-gradient(to right,#941ECF  20%, #CF1512 75%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.interview-right-lower-container{
    text-align: justify;
    font-size: clamp(14px, 1vw, 18px); 
    max-height: 30vh;
    font-weight: 600;
    line-height: 1.75rem;
}

.interview-left-container {
    max-height: 100vh;
    margin-right: 75px;

    
}

#interview .interview-left-container .image {
    z-index: 20;

    max-height: 70vh;
    width: 100%;  
    max-height: 100%;
    object-fit: contain;
    box-shadow: inset 0 0 80px rgba(0,0,0,0.8);
 }


 @media (max-width: 1200px) {

   

    .side-buttons{
        display: none;
    }

    #interview{
        padding: 2rem;
    }

    .interview-container{
        gap: 50px;
    }

    .interview-left-container, .interview-right-container{
        margin: 10px;
    }

    .interview-right-upper-container{
        font-size: 30px;
    line-height: 3.5rem;
    margin-bottom: 2rem;

    }

    .interview-right-lower-container{
        font-size: 16px;
        line-height: 1.5rem;

    }

}

@media (max-width: 750px) {



    .side-buttons{
        display: none;
    }

    #interview{
        padding: 0rem 1rem;
        box-sizing: border-box;
        
    }

    .interview-left-container{
        padding: 0.5rem 0rem 1rem 0rem;
    }

    .interview-container {
        margin: 0; /* Removes default margin */
        padding: 0.5rem;
        gap: 0;
    }

    .interview-left-container, .interview-right-container{
        margin: 0;
    }

    .interview-container{
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .interview-right-upper-container{
        font-size: 30px;
        line-height: 2rem;
        margin-bottom: 1rem;

    }

    .interview-right-lower-container{
        font-size: 14px;
        line-height: 1.4rem;

    }

    .interview-left-container img{
            max-width: 100%;
            max-height: 35vh;
        }

}

@media screen and (max-width : 385px) {
    .interview-right-upper-container{
        font-size: 20px;

    }

    .interview-right-lower-container{
        font-size: 12px;
        line-height: 1.2rem;

    }

}